var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.myPageLocator
        ? _c("v-runtime-template", {
            attrs: {
              template:
                `<div slot=default style ='text-align: -webkit-center'>` +
                _vm.myPageLocator +
                "</div>",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }