import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "90%" } },
    [
      _c(
        VCard,
        { attrs: { flat: "" } },
        [
          _c(
            VTabs,
            { attrs: { vertical: "" } },
            [
              _c(VTab, { staticStyle: { "justify-content": "start" } }, [
                _c(
                  "div",
                  [
                    _c(VIcon, { attrs: { left: "" } }, [
                      _vm._v("mdi-content-paste"),
                    ]),
                    _vm._v(
                      _vm._s(_vm.$t("myPage.tab_usage")) + "\n                "
                    ),
                  ],
                  1
                ),
              ]),
              false
                ? _c(VTab, { staticStyle: { "justify-content": "start" } }, [
                    _c(
                      "div",
                      [
                        _c(VIcon, { attrs: { left: "" } }, [
                          _vm._v("mdi-content-paste"),
                        ]),
                        _vm._v(
                          _vm._s(_vm.$t("myPage.tab_billing")) +
                            "\n                "
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(VTab, { staticStyle: { "justify-content": "start" } }, [
                _c(
                  "div",
                  [
                    _c(VIcon, { attrs: { left: "" } }, [_vm._v("mdi-lock")]),
                    _vm._v(
                      _vm._s(_vm.$t("myPage.tab_privacy")) +
                        "\n                "
                    ),
                  ],
                  1
                ),
              ]),
              _c(VTab, { staticStyle: { "justify-content": "start" } }, [
                _c(
                  "div",
                  [
                    _c(VIcon, { attrs: { left: "" } }, [
                      _vm._v("mdi-account-circle-outline"),
                    ]),
                    _vm._v(
                      _vm._s(_vm.$t("myPage.tab_admin")) + "\n                "
                    ),
                  ],
                  1
                ),
              ]),
              _c(VTabItem, [_c("PersonalUsagePage")], 1),
              false
                ? _c(VTabItem, [
                    _vm._v("\n                Billing...\n            "),
                  ])
                : _vm._e(),
              _c(VTabItem, [_c("PersonalInfo")], 1),
              _c(VTabItem, [_c("AdminUsagePage")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }