import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "90%" } },
    [
      _c(
        VCard,
        { attrs: { flat: "" } },
        [
          _c(VCardTitle, [
            _c("h2", [_vm._v(" " + _vm._s(_vm.$t("myPage.usage_title")))]),
          ]),
          _c(VDivider),
          _c(VCardText, { staticStyle: { "margin-top": "10px" } }, [
            _c(
              "div",
              [
                _c(VRow, { staticClass: "ma-0 pa-0" }, [
                  _c(
                    "div",
                    { staticStyle: { width: "175px" } },
                    [
                      _c(VSelect, {
                        attrs: {
                          items: _vm.months,
                          "item-text": "label",
                          "item-value": "label",
                          outlined: "",
                          dense: "",
                        },
                        on: { change: _vm.changeSelectedMonth },
                        model: {
                          value: _vm.selectedMonth,
                          callback: function ($$v) {
                            _vm.selectedMonth = $$v
                          },
                          expression: "selectedMonth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { width: "175px", "margin-left": "30px" } },
                    [
                      _c(
                        VMenu,
                        {
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    VTextField,
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { outlined: "", dense: "" },
                                          model: {
                                            value: _vm.start.date,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.start, "date", $$v)
                                            },
                                            expression: "start.date",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.start.menu,
                            callback: function ($$v) {
                              _vm.$set(_vm.start, "menu", $$v)
                            },
                            expression: "start.menu",
                          },
                        },
                        [
                          _c(VDatePicker, {
                            attrs: { "no-title": "", scrollable: "" },
                            on: { input: _vm.updateSelectedStartDate },
                            model: {
                              value: _vm.start.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.start, "date", $$v)
                              },
                              expression: "start.date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { margin: "8px" } }, [_vm._v("to")]),
                  _c(
                    "div",
                    { staticStyle: { width: "175px" } },
                    [
                      _c(
                        VMenu,
                        {
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    VTextField,
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { outlined: "", dense: "" },
                                          model: {
                                            value: _vm.end.date,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.end, "date", $$v)
                                            },
                                            expression: "end.date",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.end.menu,
                            callback: function ($$v) {
                              _vm.$set(_vm.end, "menu", $$v)
                            },
                            expression: "end.menu",
                          },
                        },
                        [
                          _c(VDatePicker, {
                            attrs: { "no-title": "", scrollable: "" },
                            on: { input: _vm.updateSelectedEndDate },
                            model: {
                              value: _vm.end.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.end, "date", $$v)
                              },
                              expression: "end.date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c(VDataTable, {
                      staticClass: "elevation-1",
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.displayedUsageData,
                        "items-per-page": _vm.pagination.itemsPerPage,
                        pagination: _vm.pagination,
                        loading: _vm.loading,
                        "hide-default-footer": "",
                        "no-data-text": "No data available",
                        "loading-text": "Loading... Please wait",
                      },
                      on: {
                        "update:pagination": function ($event) {
                          _vm.pagination = $event
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item.serviceType",
                          fn: function ({ item }) {
                            return [
                              _c("div", { staticClass: "row-title" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.formatServiceType(
                                        item.value.serviceType
                                      ).title
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                              _c("div", { staticClass: "row-detail" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.formatServiceType(
                                        item.value.serviceType
                                      ).detail
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item.metadata",
                          fn: function ({ item }) {
                            return [
                              _c("div", { staticClass: "row-title" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.formatMetadata(item.value).title
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                              _c("div", { staticClass: "row-detail" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.formatMetadata(item.value).detail
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item.measurement",
                          fn: function ({ item }) {
                            return [
                              _c("div", { staticClass: "row-title" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.value.plan) +
                                    "\n                            "
                                ),
                              ]),
                              _c("div", { staticClass: "row-detail" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.formatMeasurement(item.value)) +
                                    "\n                            "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "item.issuedTimeStamp",
                          fn: function ({ item }) {
                            return [
                              _c("div", { staticClass: "row-title" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.formatDate(item.value.issuedTimeStamp)
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c(VPagination, {
                      attrs: { length: _vm.pages },
                      on: { input: _vm.loadPage },
                      model: {
                        value: _vm.pagination.page,
                        callback: function ($$v) {
                          _vm.$set(_vm.pagination, "page", $$v)
                        },
                        expression: "pagination.page",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }